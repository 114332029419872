<template>
  <b-img
    :src="logoImage"
    :alt="alt"
    :width="width"
    :height="height"
    :class="className"
  />
</template>

<script>
import { computed } from "@vue/composition-api"

import useAppConfig from "@core/app-config/useAppConfig"

export default {
  props: {
    width: {
      type: String,
      default: "250",
    },
    height: {
      type: String,
      default: "auto",
    },
    className: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "logo",
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const logoImage = computed(() => {
      if (skin.value === "dark") {
        return require("@/assets/images/logo/white-logo.webp")
      }

      return require("@/assets/images/logo/dark-logo.webp")
    })

    return {
      logoImage,
    }
  },
}
</script>
